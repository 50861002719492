<template>
  <div
    id="uploadFolder"
    class="m-2 p-3"
    :ref="'uploadFolder-' + name"
    :class="{'error': messageType === 'error', 'success': messageType === 'success'}"
  >
    <div class="info-box" v-if="showOptions" title="download sample" @click="downloadSample">
      <div class="circle" v-for="n in 3" :key="n"></div>
    </div>

    <div class="icon-container">
      <div class="folder" v-if="icon ==='folder'">
        <svg
          version="1.1"
          id="Layer_1"
          focusable="false"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 384 512"
          style="enable-background:new 0 0 384 512;"
          xml:space="preserve"
        >
          <path
            fill="currentColor"
            d="M329.6,402v18c0,20.7-15.4,37.5-34.3,37.5H89.5c-18.9,0-34.3-16.8-34.3-37.5V95.3c0-20.7,15.4-37.5,34.3-37.5h18.1v37.5
          H89.5V420h205.8v-18H329.6z M384,103v259.2c0,20.7-15.4,37.5-34.3,37.5H143.9c-18.9,0-34.3-16.8-34.3-37.5V37.5
          c0-20.7,15.4-37.5,34.3-37.5h145.9c9.1,0,17.8,4,24.2,11l60,65.5C380.4,83.5,384,93.1,384,103z M292.5,40.6V100h54.4L292.5,40.6z
          M349.7,362.2V137.4h-74.3c-9.5,0-17.2-8.4-17.2-18.7V37.5H143.9v324.7H349.7z M240.1,458.7v18H34.3V152h18.1v-37.5H34.3
          C15.4,114.6,0,131.4,0,152v324.7c0,20.7,15.4,37.5,34.3,37.5h205.8c18.9,0,34.3-16.8,34.3-37.5v-18H240.1z"
          />
        </svg>
      </div>
      <div class="file" v-if="icon ==='file'">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="far"
          data-icon="file"
          class="svg-inline--fa fa-file fa-w-12"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
        >
          <path
            fill="currentColor"
            d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"
          />
        </svg>
      </div>
    </div>

    <div class="title">{{name}}</div>
    <div class="sublabel flex-grow-1">{{description}}</div>

    <div class="progress mt-2" style="height: 15px;">
      <div
        class="progress-bar"
        role="progressbar"
        :style="{'width': checkComplete +'%'}"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div class="units-text mt-1">{{numberOfFilesVerified}} out of {{totalFiles}} {{totalFileNote}}</div>

    <div class="units-text mt-1">
      {{notes}}
    </div>
  </div>
</template>

<script>
import * as csv from 'jquery-csv'

export default {
  name: 'uploadFolder',
  props: {
    folder: Object
  },
  data () {
    return {
      messageType: null,
      numberOfFilesVerified: 0
    }
  },
  mounted () {
    if (this.isAdvancedUpload()) {
      this.initDropArea()
    }
  },
  computed: {
    computeProgress () {
      return this.progress
    },
    checkComplete () {
      return (this.numberOfFilesVerified / this.totalFiles) * 100
    },
    progress () {
      return this.folder.progress
    },
    totalFiles () {
      return this.folder.totalFiles
    },
    name () {
      return this.folder.name
    },
    description () {
      return this.folder.description
    },
    icon () {
      return this.folder.icon
    },
    showOptions () {
      return ['settings.json', 'data.csv'].includes(this.name)
    },
    totalFileNote () {
      return this.folder.name === 'models' ? 'max' : ''
    },
    notes () {
      return this.folder.note
    }
  },
  methods: {
    downloadSample () {
      this.$store.dispatch('downloadSampleData', this.name)
    },
    isAdvancedUpload () {
      let div = document.createElement('div')
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      )
    },
    initDropArea () {
      let dropArea = this.$refs['uploadFolder-' + this.name];

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, preventDefaults, false)
      })

      function preventDefaults (e) {
        e.preventDefault()
        e.stopPropagation()
      }

      ['dragenter', 'dragover'].forEach((eventName) => {
        dropArea.addEventListener(eventName, highlight, false)
      });
      ['dragleave', 'drop'].forEach((eventName) => {
        dropArea.addEventListener(eventName, unhighlight, false)
      })

      function highlight (e) {
        dropArea.classList.add('highlight')
      }

      function unhighlight (e) {
        dropArea.classList.remove('highlight')
      }

      dropArea.addEventListener(
        'drop',
        (e) => {
          this.handleDrop(e, 'drag')
        },
        false
      )
    },
    checkFileType (key, uploadFolder) {
      if (
        uploadFolder === 'context.json' ||
        uploadFolder === 'settings.json' ||
        uploadFolder === 'models'
      ) {
        return key === 'application/json'
      } else {
        return key === 'application/vnd.ms-excel'
      }
    },
    setMessageType (type) {
      this.messageType = type
    },
    setNotificationMessage (flag, message, type) {
      this.setMessageType(type)

      this.$store.commit('setNotificationObject', {
        flag: flag,
        message: message,
        type: type
      })
    },
    updateNumberOfFiles () {
      this.numberOfFilesVerified += 1
    },
    readerError (err) {
      console.error('error', err)
      this.setNotificationMessage(true, 'reader error', 'error')
    },
    emitToParent (data) {
      this.setNotificationMessage(true, 'correct file type', 'success')
      this.updateNumberOfFiles()

      this.$emit('dataUpload', this.name, data)
    },
    checkSettingsJSON (file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onerror = this.readerError

        reader.onload = (e) => {
          let settingsJSON = JSON.parse(e.target.result)
          resolve(settingsJSON)
        }

        reader.readAsText(file)
      })
    },
    checkContextJSON (file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onerror = this.readerError

        reader.onload = (e) => {
          let contextJSON = JSON.parse(e.target.result)
          resolve(contextJSON)
        }

        reader.readAsText(file)
      })
    },
    checkDataCSV (file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.onerror = this.readerError

        reader.onload = (e) => {
          let dataCSV = csv.toObjects(e.target.result)
          resolve(dataCSV)
        }

        reader.readAsText(file)
      })
    },
    checkFileName (file) {
      switch (file.name) {
        case 'settings.json':
          return this.checkSettingsJSON(file).then((e) => {
            this.emitToParent(e)
          })
        case 'context.json':
          return this.checkContextJSON(file).then((e) => {
            e['metadata']['ID'] = file.name
            this.emitToParent(e)
          })
        case 'data.csv':
          return this.checkDataCSV(file).then((e) => {
            this.emitToParent(e)
          })
      }
    },
    handleDrop (event, mouseEvent) {
      if (mouseEvent === 'drag') {
        let dataTransfer = event.dataTransfer.files
        if (['context.json', 'data.csv', 'settings.json'].includes(this.name)) {
          let file = dataTransfer[0]

          const typeCheck = this.checkFileType(file.type, this.name)

          if (typeCheck) {
            this.checkFileName(file)
          } else {
            this.setNotificationMessage(true, 'incorrect file type', 'error')
          }
        } else {
          let models = dataTransfer

          Object.keys(models).forEach((key) => {
            let file = models[key]

            let typeCheck = this.checkFileType(file.type, this.name)

            if (typeCheck) {
              let ID = file.name

              this.checkContextJSON(file).then((e) => {
                e['metadata']['ID'] = ID
                this.emitToParent(e)
              })
            } else {
              this.setNotificationMessage(true, 'incorrect file type', 'error')
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
$info-circle-size: 4px;
$info-circle-offset: 10px;

#uploadFolder {
  position: relative;
  width: 200px;

  border: 1px solid $off-white;
  border-radius: 4px;

  display: flex;
  flex-direction: column;

  &.error {
    border-color: $highlight-color !important;
  }
  &.success {
    border-color: $blue;
  }
  &.highlight {
    background: steelblue;
  }
  .icon-container {
    svg {
      height: 45px;
    }
  }

  .info-box {
    position: absolute;
    top: $info-circle-offset;
    right: $info-circle-offset;

    cursor: pointer;

    .circle {
      margin-top: 3px;
      border-radius: 50%;
      background: $off-white;
      width: $info-circle-size;
      height: $info-circle-size;
    }

    &:hover > .circle {
      background: $highlight-color;
    }
  }
}
</style>
