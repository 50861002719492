/**
 * import THREEjs library
 */
const THREE = require('three')

/**
 * @param {Object} camera THREEJS Perspective Camera
 * @param {float} sizeToFitOnScreen camera radius number how far offset from center ofmodel
 * @param {float} boxSize size of box in threejs units
 * @param {Vector3} boxCenter THREEJS Vector3 Object locating the center of box in x,y,z
 */
export function frameArea (camera, sizeToFitOnScreen, boxSize, boxCenter) {
  const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.3

  const halfFovY = THREE.Math.degToRad(camera.fov * 0.5)
  const distance = halfSizeToFitOnScreen / Math.tan(halfFovY)
  // compute a unit vector that points in the direction the camera is now
  // in the xz plane from the center of the box
  const direction = new THREE.Vector3()
    .subVectors(camera.position, boxCenter)
    .multiply(new THREE.Vector3(1, 0, 1))
    .normalize()

  // move the camera to a position distance units way from the center
  // in whatever direction the camera was from the center already
  camera.position.copy(direction.multiplyScalar(distance).add(boxCenter))

  // pick some near and far values for the frustum that
  // will contain the box.
  camera.near = boxSize / 100
  camera.far = boxSize * 100

  camera.updateProjectionMatrix()

  // point the camera to look at the center of the box
  camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z)
}
/**
 * @param {Object} metricSelect metric object selected
 * @return String of selected metric name parsed
 */
export function parseMetricName (metricSelected) {
  return '_' + metricSelected.split('out_')[1].toLowerCase()
}

export function parseModelName (modelName) {
  return modelName.split('_option')[0]
}
