<template>
  <div id="mapToolbar">
    <div
      class="icon-svg mb-4"
      @click="toggleRotate"
      :title="
        toggleRotateFlag === true
          ? 'stop model rotation'
          : 'start model rotation'
      "
    >
      <planIcon v-show="toggleRotateFlag === true" />
      <playIcon v-show="toggleRotateFlag == false" />
    </div>

    <!-- <div class="icon-svg mb-4" title="select random design">
      <randomIcon @click="randomIteration" />
    </div> -->

    <div
      class="mb-4"
      @click="viewDropdownFlag = !viewDropdownFlag"
      title="open view panel"
    >
      <viewIcon
        class="icon-svg"
        :class="{ active: viewDropdownFlag === true }"
      />
      <div
        class="view-dropdown map-dropdown d-inline-flex p-2"
        v-if="viewDropdownFlag === true"
      >
        <div class="icon-sub-svg m-2" title="plan view">
          <planIcon
            @click.stop="
              moveCameraToPosition(planView)
            "
          />
        </div>
        <div class="icon-sub-svg m-2" title="perspective view">
          <perspectiveIcon
            @click.stop="
              moveCameraToPosition(perspectiveView)
            "
          />
        </div>
        <div class="icon-sub-svg icon-svg m-2" title="elevation view">
          <elevationIcon
            @click.stop="
              moveCameraToPosition(elevationView)
            "
          />
        </div>
      </div>
    </div>

    <div
      class="mb-4"
      @click="atmosphereDropdownFlag = !atmosphereDropdownFlag"
      title="open atmosphere panel"
    >
      <atmosphereIcon
        class="icon-svg"
        :class="{ active: atmosphereDropdownFlag === true }"
      />
      <div
        class="atmosphere-dropdown map-dropdown d-inline-flex p-2"
        v-if="atmosphereDropdownFlag === true"
      >
        <div
          class="icon-sub-circle m-2"
          id="atmosphere-white"
          @click.stop="changeAmbientLightColor(0xe8ecff, 1, 1, 1)"
        ></div>
        <div
          class="icon-sub-circle m-2"
          id="atmosphere-orange"
          @click.stop="changeAmbientLightColor(0xf7c588, 1300, 300, -200)"
        ></div>
      </div>
    </div>

    <!--
    <div class="mb-4">
      <collectionHeart :currentModelID="parseInt(modelID)" />
    </div>
    -->

    <!-- <div class="mb-4" title="open search panel">
      <searchIcon
        class="icon-svg"
        @click="searchDropdownFlag = !searchDropdownFlag"
        :class="{ active: searchDropdownFlag === true }"
      />
      <div
        class="search-dropdown map-dropdown d-inline-flex p-2"
        v-if="searchDropdownFlag === true"
      >
        <div>
          <b-form-input
            v-on:keyup.enter="submitSearchID"
            placeholder="search model ID"
            v-model.number="IDtoSearch"
            type="number"
            :state="searchInputState"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback"
            >number must be in range of 0-{{
              numberOfModels.length - 1
            }}</b-form-invalid-feedback
          >
        </div>
      </div>
    </div> -->
    <!--
    <div class="mb-4" v-if='!narrativeMode'>
      <bookmarkIcon
        @click.stop="toggleNarrativeFlag"
        class="icon-svg"
        :class="{ active: narrativeFlag === true }"
      />
    </div>
    -->
  </div>
</template>

<script>
/**
 * Imports svgs as icon constructors
 * @constructor
 */
// import randomIcon from '@/assets/icons/dice.svg'
import viewIcon from '@/assets/icons/views.svg'
import playIcon from '@/assets/icons/play.svg'
import perspectiveIcon from '@/assets/icons/3d.svg'
import planIcon from '@/assets/icons/2d.svg'
import elevationIcon from '@/assets/icons/skyline.svg'
import atmosphereIcon from '@/assets/icons/atmosphere.svg'
// import searchIcon from '@/assets/icons/search.svg'
// import bookmarkIcon from '@/assets/icons/bookmark.svg'
/**
 * @vue-import heart child component
 */
// import collectionHeart from '@/components/Utility/collection_heart.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'mapToolbar',
  components: {
    // randomIcon,
    viewIcon,
    playIcon,
    perspectiveIcon,
    planIcon,
    // searchIcon,
    elevationIcon,
    atmosphereIcon
    // collectionHeart,
    // monitorIcon,
    // bookmarkIcon
  },
  props: {
    toggleRotateFlag: Boolean
  },
  data () {
    return {
      /**
       * @type {Boolean}
       * switches view dropdown
       */
      viewDropdownFlag: false,
      /**
       * @type {Boolean}
       * switches atmosphere dropdown
       */
      atmosphereDropdownFlag: false,
      /**
       * switches search dropdown
       */
      searchDropdownFlag: false,
      /**
       * @type {Int}
       * ID user submitted for searching
       */
      IDtoSearch: null
    }
  },
  computed: {
    ...mapGetters({
      cameraRadius: 'getCameraRadius',
      modelID: 'getModelID',
      numberOfModels: 'getNumberOfModels',
      narrativeFlag: 'getNarrativeFlag',
      narrativeMode: 'getNarrativeMode'
    }),
    perspectiveView () {
      return {
        x: this.cameraRadius,
        y: this.cameraRadius,
        z: -this.cameraRadius
      }
    },
    planView () {
      return { x: 0, y: this.cameraRadius, z: -15 }
    },
    elevationView () {
      return { x: this.cameraRadius, y: 0, z: 15 }
    },
    searchInputState () {
      return (
        this.IDtoSearch === 0 ||
        (this.IDtoSearch &&
          this.IDtoSearch > -1 &&
          this.IDtoSearch < this.numberOfModels.length)
      )
    }
  },
  methods: {
    toggleRotate () {
      this.$emit('toggleRotate', {})
    },
    randomIteration () {
      this.$emit('randomIteration', {})
    },
    moveCameraToPosition (cameraPosition) {
      this.$store.commit('setCameraPosition', cameraPosition)
    },
    changeAmbientLightColor (color, x, y, z) {
      this.$emit('changeAmbientLightColor', color, x, y, z)
    },
    /**
     * @output handles search event
     */
    submitSearchID () {
      if (this.searchInputState) {
        this.$store.commit(
          'setSearchIterationID',
          this.numberOfModels[this.IDtoSearch]
        )
        this.searchDropdownFlag = false
      }
    },
    toggleNarrativeFlag () {
      this.$store.commit('setNarrativeFlag', !this.narrativeFlag)
    }
  }
}
</script>
<style lang="scss">
$view-dropdown-height: 25px;

#mapToolbar {
  position: absolute;
  right: 0;
  width: 75px;

  top: $navbar-height;
  // height: calc(100vh - #{$navbar-height});

  pointer-events: all;

  user-select: none;

  background: transparent;
  border-top: none;

  padding: 21px;
}

.map-dropdown {
  position: absolute;
  right: 55px;

  background: $black;
}

.view-dropdown {
  top: calc(#{$view-dropdown-height} * 3);
}

.atmosphere-dropdown {
  top: calc(#{$view-dropdown-height} * 4.5);
}

.search-dropdown {
  width: calc(#{$view-dropdown-height} * 5);
  top: calc(#{$view-dropdown-height} * 6.5);
}

.icon-sub-svg {
  width: calc(#{$icon-size} - 8px);
  height: calc(#{$icon-size} - 8px);
}

.icon-sub-circle {
  width: calc(#{$icon-size} - 12px);
  height: calc(#{$icon-size} - 12px);
  border-radius: 50%;
}

#atmosphere-blue {
  background-color: $blue-sky;
}

#atmosphere-white {
  background-color: $white;
}

#atmosphere-orange {
  background-color: $brown-sky;
}
</style>
