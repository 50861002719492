<template>
  <div id="inputPanel">
    <div v-for="(button, key, index) in inputPanelButtons" :key="index" title="Controls Panel">
      <transition name="fade" :duration="{ enter: 800, leave: 800 }">
        <div class="standard-text title hoverable" @click="button.active = !button.active" v-if="!button.active">{{button.label}}</div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'inputPanel',
  computed: {
    ...mapGetters({
      inputPanelButtons: 'getExplorePanel'
    })
  }
}
</script>
<style lang="scss">
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
#inputPanel{
  position: fixed;
  display: flex;
  bottom: 0px;

  left: 0px;
  width: 100%;

  pointer-events: all;

  user-select: none;

  background: transparent;
  border-top: none;

  padding: 1rem 1rem 1rem 21px;
}
</style>
