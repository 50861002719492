<template>
  <div id="model" :ref="'model'">
    <mapToolbar
      @toggleRotate="toggleRotate"
      :toggleRotateFlag="toggleRotateFlag"
      @randomIteration="randomIteration"
      @changeAmbientLightColor="changeAmbientLightColor"
    />

    <div ref="modelView" id="modelView"></div>

    <inputSelector
      :ref="'inputSelector'"
      @callRemoveModel="removeModel"
    />

    <inputPanel />

    <dragDrop
      v-if="showDragDrop"
      @setDragDropFlag="setDragDropFlag"
    />
  </div>
</template>

<script>
/**
 * @vue-import inputselector child component
 *
 */
import inputSelector from '@/components/explore/inputSelector.vue'
/**
 * @vue-import inputpanel child component
 */
import inputPanel from '@/components/explore/inputPanel.vue'
/**
 * @vue-import dragDrop component
 */
import dragDrop from '@/components/dragDrop/dragDrop.vue'
/**
 * @component
 */
import mapToolbar from '@/components/explore/map_toolbar.vue'
/**
 * @vue-import store mapping store to view
 */
import { mapGetters } from 'vuex'

// import helper functions
import {
  ConstructScene,
  Lights,
  getRandomIterationID,
  disposeScene
} from '@/assets/js/helper.js'

import {
  frameArea,
  parseMetricName
} from '@/assets/js/methods.js'

import { exploreModeSettings } from '@/assets/js/library.js'
/**
 * import THREEjs library
 */
const THREE = require('three')
/**
 * import orbit controls library
 */
const OrbitControls = require('three-orbit-controls')(THREE)
/**
 * import tweenjs library
 */
const TWEEN = require('@tweenjs/tween.js').default

let scene = null
let lights = null
let camera = null
let renderer = null
let controls = null
let currentModelObject = false
const loader = new THREE.ObjectLoader()

export default {
  name: 'model',
  data () {
    return {
      /**
       * @type {Boolean}
       * switches rotate
       */
      toggleRotateFlag: false,

      /**
       * @type {Boolean}
       * switches whether initial controls and scene settings have been saved
       */
      controlsSetFlag: false,
      /**
       * @type {Boolean}
       */
      showDragDrop: false
    }
  },
  components: {
    'inputSelector': inputSelector,
    inputPanel,
    dragDrop,
    mapToolbar
  },
  created () {
    /**
     * @type dispatch
     * calls store to pull data.csv + settings.json
     * ROOT APPLICATION START
     * APPLICATION STARTS WITH readModelData as an init point
     *
     */
    const location = window.location.href

    if (this.modelDataFlag === false) {
      if (
        location.indexOf('https://scoutbeta.kpfui.dev/?project') !== -1 ||
        location.indexOf('https://scout.kpfui.dev/?project') !== -1 ||
        location.indexOf('https://alpha.kpfui.dev/?project') !== -1 ||
        location.indexOf('https://scoutview.kpfui.dev/?project') !== -1
      ) {
        this.$store.dispatch('readModelData', this.$route.query)
      } else if (
        location === 'https://scoutbeta.kpfui.dev/' ||
        location === 'https://scout.kpfui.dev/' ||
        location === 'https://alpha.kpfui.dev/' ||
        location === 'https://scoutview.kpfui.dev'
      ) {
        this.scoutMode('public')
      } else if (location.indexOf('localhost') !== -1) {
        // this.scoutMode("public");
        this.scoutMode('custom')
      }
    }
  },
  mounted () {
    /**
     * init the scene with container, scene, camera, renderer
     */
    this.init()

    /**
     * reloads scene from saved settings
     *
     */
    if (this.modelDataFlag === true) {
      // load inital settings from public folder
      this.reloadingContext(this.$route.params['filterUpdate'] === true)
    }
    /**
     * Subscribe to the Vuex Store
     */
    this.subscribeToStore()

    /**
     * start animation loop
     */
    this.animate()
  },
  beforeDestroy () {
    /**
     * Unsubscribe from the Vuex Store
     */
    this.unsubscribeFromStore()

    this.$store.commit('resetMetricToggles', true)
    this.$store.commit('setMetricSelected', null)
  },
  destroyed () {
    // destory the animation loop
    cancelAnimationFrame(this.animationFrameID)

    window.removeEventListener('resize', this.onWindowResize, false)

    disposeScene(scene, renderer)
  },
  filters: {
    rotateTextFilter (toggle) {
      return toggle === true ? 'Stop' : 'Play'
    }
  },
  computed: {
    ...mapGetters({
      selectedModel: 'getSelectedModel',
      metricSelected: 'getMetricSelected',
      metricObject: 'getMetricObject',
      numberOfModels: 'getNumberOfModels',
      contextObject: 'getContextObject',
      modelSettings: 'getModelSettings',
      cameraRadius: 'getCameraRadius',
      modelDataFlag: 'getModelDataFlag',
      modelID: 'getModelID',
      metric: 'getMetric',
      settings: 'getSettingsData',
      defaultFlag: 'getDefaultFlag',
      projectLocation: 'getProjectLocation',
      projectScale: 'getProjectScale',
      camerPosition: 'getCameraPosition'
    }),
    /**
     * @returns document height minus navbar height
     */
    getCanvasHeight () {
      return document.getElementById('navbar').getBoundingClientRect().height
    },
    perspectiveView () {
      return {
        x: this.cameraRadius,
        y: this.cameraRadius,
        z: -this.cameraRadius
      }
    },
    container () {
      return this.$refs['modelView']
    }
  },
  methods: {
    scoutMode (mode) {
      switch (mode) {
        case 'custom':
          this.$store.dispatch('readModelData', this.$route.query)
          break

        case 'public':
          this.setDragDropFlag(true)
          this.$store.commit('setLoadingFlag', false)
          this.$store.commit('setExplorePanel', ['Controls', false])
          this.$store.commit('setScoutMode', 'public')

          break
      }
    },
    setDragDropFlag (flag) {
      this.showDragDrop = flag
    },
    /**
     * @param object json model
     * @param addmodel callback function to add model to scene
     * @param onProgress callback progress function
     * @param projectScale int between 0 - 1
     */
    loadObject (object, addModel, onProgress, projectScale) {
      loader.parse(object, (obj) => {
        obj.scale.set(projectScale, projectScale, projectScale)

        addModel(obj)
      })
    },
    /**
     * switches autorotate on/off
     * controls.autorotate turns off scene rotation
     * togglerotateflg is global flag for UI
     */
    toggleRotate () {
      controls.autoRotate = !controls.autoRotate
      this.toggleRotateFlag = controls.autoRotate
    },
    /**
     * Generates value between 0 - maximum number of iterations of dataset
     *  0 - maximum number of possible iterations in model set
     *  once genereated submits value to app store
     */
    randomIteration () {
      // let randomGen = Math.floor(Math.random() * this.numberOfModels.length - 1)
      let randomGen = getRandomIterationID(this.numberOfModels)
      this.$store.commit(
        'setSearchIterationID',
        this.numberOfModels[randomGen]
      )
    },
    /**
     * inits the scene container
     * sets width and height of scene
     * fires light function
     * creates renderer constrcutor
     * sets up camera
     * creates window event listener for resizing
     */
    init () {
      /**
       * @type {class}
       * instantiates the scene as a class object
       */
      const createScene = new ConstructScene(
        THREE.Scene,
        new THREE.Color('rgb(24,24,24)'),
        new THREE.Fog(0x1a2050, 10000, 10000)
      )
      scene = createScene.scene

      createScene.constructRenderer(
        THREE.WebGLRenderer,
        this.getCanvasHeight,
        THREE.PCFSoftShadowMap
      )
      renderer = createScene.renderer

      createScene.constructCamera(
        THREE.PerspectiveCamera,
        this.getCanvasHeight
      )
      camera = createScene.camera
      camera.up = new THREE.Vector3(0, 0, 1)

      createScene.constructControls(OrbitControls, renderer.domElement)
      controls = createScene.controls

      // setup lights
      lights = new Lights(
        THREE.AmbientLight,
        THREE.DirectionalLight,
        THREE.CameraHelper
      )

      lights.createDirectionalLight()
      // lights.ambient.color = new Color(this.atmosphereColor)

      scene.add(lights.ambient)
      scene.add(lights.directionalLight)
      scene.add(lights.directionalLight2)

      this.container.appendChild(renderer.domElement)

      // window resize event
      window.addEventListener('resize', this.onWindowResize, false)
    },
    /**
     * animation loop
     * tween.update allows for camera tween motion
     */
    animate () {
      // assign ID to the animation frame to use for destruction
      this.animationFrameID = requestAnimationFrame(this.animate)

      TWEEN.update()
      controls.update()
      renderer.render(scene, camera)

      // set current camera position to saved setting
      exploreModeSettings['camera']['position'] = camera.position
    },
    /**
     * removes the current model based on name attribute
     * where the number is model iteration followed by _option
     *  @param modelName as {#}_option
     */
    removeModel: function (modelName) {
      console.debug('Removing model from scene', modelName)

      let selectedObject = scene.getObjectByName(modelName)

      selectedObject.children.forEach(function (child, i) {
        child.geometry.dispose()
        child.material.dispose()
        child = undefined
      })

      scene.remove(selectedObject)

      selectedObject = undefined

      renderer.dispose()
      renderer.renderLists.dispose()
    },
    frameArea (sizeToFitOnScreen, boxSize, boxCenter) {
      const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.3

      const halfFovY = THREE.Math.degToRad(camera.fov * 0.5)
      const distance = halfSizeToFitOnScreen / Math.tan(halfFovY)
      // compute a unit vector that points in the direction the camera is now
      // in the xz plane from the center of the box
      const direction = new THREE.Vector3()
        .subVectors(camera.position, boxCenter)
        .multiply(new THREE.Vector3(1, 0, 1))
        .normalize()

      // move the camera to a position distance units way from the center
      // in whatever direction the camera was from the center already
      camera.position.copy(direction.multiplyScalar(distance).add(boxCenter))

      // pick some near and far values for the frustum that
      // will contain the box.
      camera.near = boxSize / 100
      camera.far = boxSize * 100

      camera.updateProjectionMatrix()

      // point the camera to look at the center of the box
      camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z)
    },
    /**
     * @param model json parased model
     * places model in the scene after load is complete
     */
    addModel: function (model) {
      console.debug('adding model to scene', this.modelID)

      // check to see if there is a model to remove
      if (currentModelObject) {
        if (scene.getObjectByName(currentModelObject.name) !== undefined) {
          this.removeModel(currentModelObject.name)
        }
        currentModelObject = false
      }
      // check to see if model center context has been set
      // if false, then get center of model and set as model center
      if (this.controlsSetFlag === false) {
        // const bb = new THREE.Box3()
        const box = new THREE.Box3().setFromObject(this.contextObject)
        // box.setFromObject(model);

        const boxSize = box.getSize(new THREE.Vector3()).length()
        const boxCenter = box.getCenter(new THREE.Vector3())
        const cameraRadius = boxSize * 1.5

        // set the camera to frame the box
        frameArea(camera, cameraRadius, boxSize, boxCenter)

        controls.maxDistance = boxSize * 10
        controls.target.copy(boxCenter)
        controls.update()

        scene.add(this.contextObject)

        this.$store.commit('setCameraRadius', cameraRadius)

        this.controlsSetFlag = true
      }

      // update model active name
      model.name = this.modelID + '_option'

      currentModelObject = model

      // add model
      scene.add(model)
    },
    moveCameraToPosition (cameraPosition) {
      if (controls.autoRotate) {
        controls.autoRotate = false
        this.toggleRotateFlag = false
      }
      // disable controls during animation
      controls.enabled = false
      // get position
      const from = camera.position
      let tween = new TWEEN.Tween(from).to(cameraPosition, 1500)
      tween.easing(TWEEN.Easing.Exponential.InOut)
      tween.start()

      // tween = new TWEEN.Tween(controls.target).to({ x: 0, y: 0, z: 0 }, 1500)
      // tween.easing(TWEEN.Easing.Sinusoidal.InOut)
      // tween.start()
      // renable controls after animation
      controls.enabled = true
    },
    /**
     * @output
     *  camera postion
     *  controls position
     *  add context object
     *  turn off loading after context is loaded
     *  @param bypass { Boolean } to load object directly or set by input values based on routing
     *
     */
    reloadingContext: function (bypass) {
      scene.add(this.contextObject)

      if (!bypass) {
        this.loadObject(
          this.selectedModel,
          this.addModel,
          this.onProgress,
          this.projectScale
        )
      }
    },
    /**
     * @param contextObject scene context scene object saving to store
     * @output
     * name the context object
     * store context object in store
     */
    saveContext (contextObject) {
      this.$store.commit(
        'setContextObject',
        Object.assign(contextObject, { _isVue: true })
      )
    },
    /**
     * @param metric scene metric object
     * @output adds to scene
     */
    addMetric (metric) {
      console.debug('adding metric')

      Object.assign(metric, { _isVue: true })

      // give metric a name #_<metric_name>
      metric.name = this.metricSelected.metric

      // assign position of metric based on global model settings
      // metric.position.set(
      //   this.modelSettings.x,
      //   this.modelSettings.y,
      //   this.modelSettings.z
      // )

      this.$store.commit('setLoadingFlag', false)

      scene.add(metric)

      metric = undefined
    },
    /**
     * @param toggle Boolean
     * @output turn current model in scene model shadow on/off
     */
    toggleModelShadow (toggle) {
      currentModelObject.receiveShadow = toggle
      currentModelObject.castShadow = toggle
    },
    changeAmbientLightColor (color, x, y, z) {
      const c = new THREE.Color(color)
      lights.directionalLight.position.set(x, y, z).normalize()
      lights.ambient.color = c
    },
    /**
     * @output test screen width and adjust scene renderer
     */
    onWindowResize: function () {
      camera.aspect =
        window.innerWidth / (window.innerHeight - this.getCanvasHeight)
      camera.updateProjectionMatrix()

      renderer.setSize(
        window.innerWidth,
        window.innerHeight - this.getCanvasHeight
      )
    },
    /**
     * subscribe to VUEX store
     * https://dev.to/viniciuskneves/watch-for-vuex-state-changes-2mgj
     */
    subscribeToStore () {
      /**
       * @subscriber -> fire only when new model is selected
       */
      this.modelSelectedUnsubscribe = this.$store.subscribe(
        (mutation, state) => {
          switch (mutation.type) {
            case 'setSelectedModel':
              this.loadObject(
                this.selectedModel,
                this.addModel,
                this.onProgress,
                this.projectScale
              )

              this.$store.commit('setLoadingFlag', false)

              break
          }
        }
      )
      /**
       * @subscriber fire only when new metric parsed
       */
      this.metricUnsubscribe = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'setMetric':
            this.loadObject(
              this.metric,
              this.addMetric,
              this.onProgress,
              this.projectScale
            )

            break
        }
      })
      /**
       * @subsciber fire only when new metric is selected
       */
      this.metricSelectedUnsubscribe = this.$store.subscribe(
        (mutation, state) => {
          switch (mutation.type) {
            case 'setCameraPosition':
              this.moveCameraToPosition(this.camerPosition)
              break
            case 'setMetricSelected':
              /**
               * TURN OFF ANALYSIS
               */
              // checks metric status of the input metric as true or false
              if (
                this.metricSelected.status === false &&
                this.metricSelected.analysis_mesh === true
              ) {
                console.debug('turning off metric')

                this.$store.commit('setLoadingFlag', true)

                this.removeModel(this.metricSelected.metric)

                this.$store.commit('setLoadingFlag', false)
                /**
                 * TURN ON ANALYSIS
                 */
              } else if (
                this.metricSelected.status === true &&
                this.metricSelected.analysis_mesh === true
              ) {
                this.$store.commit('setLoadingFlag', true)
                // turn off building shadow when metric is loaded
                this.toggleModelShadow(false)
                // if the metric is in the scene remove it
                if (
                  scene.getObjectByName(this.metricSelected.metric) !==
                  undefined
                ) {
                  this.removeModel(this.metricSelected.metric)
                }

                // fetch analysis if analysis mesh exists
                if (this.metricSelected.analysis_mesh === true) {
                  this.$store.dispatch('readModelByID', {
                    ID:
                      this.modelID +
                      parseMetricName(this.metricSelected.metric),
                    type: 'metric'
                  })

                  // this.$store.commit('setLoadingFlag', false)
                }
              }
              break
          }
        }
      )
      /**
       * @subscriber fire only when new model data is ingested by store loop
       */
      this.contextUnsubscribe = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'setModelData':
            this.loadObject(
              this.contextObject,
              this.saveContext,
              this.onProgress,
              this.projectScale
            )
            break
        }
      })
    },
    // unsubscribe from store mutations
    unsubscribeFromStore () {
      this.modelSelectedUnsubscribe()
      this.metricUnsubscribe()
      this.metricSelectedUnsubscribe()
      this.contextUnsubscribe()
    }
  }
}
</script>

<style lang="scss">
#modelView {
  canvas {
    height: calc(100% - #{$navbar-height});
    touch-action: none;
    z-index: -1;
    pointer-events: all;

    outline: none;
  }
}
</style>
